<template>
    <KCourse loader-class="MBcont" course-id="2" class="MBcont">
        <div class="MBtextcont">
            <h1>Анатомия тела</h1>
            <p class="nomargin">Для прохождения курса вам понадобится чертеж.</p>
            <p class="nomargin"><a class="a-download" href="/img/files/Anatomy_part1.jpg">Скачать чертеж. Часть 1</a></p>
            <p class="nomargin"><a class="a-download" href="/img/files/Anatomy_part1.jpg">Скачать чертеж. Часть 2</a></p>
            
            <h2>1-ый шаг. Готовим трафареты</h2>
            <p>На этом занятии нам понадобится: картон, калька, карандаш, клей для бумаги, макетный нож.</p>
        </div>

        <VideoView video-id="c997604282e943b0bcf8ae3d24bb68cb"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Домашнее задание:</b></p>
            <ol>
                <li>Распечатать чертеж на двух листах формата А4, склеить по линиям тела.</li>
                <li>Сделать трафареты для заготовок: головы, тела, ноги, руки — в двух ракурсах вид спереди и сбоку.</li>
            </ol>

            <h2>2-ой шаг. Делаем заготовки из пластилина</h2>
            <p class='nomargin'>На этом занятии нам понадобятся:</p>
            <ul>
                <li>Пластилин скульптурный средней твердости, 500 гр</li>
                <li>Шпажки 4 шт</li>
                <li>Стек или нож для резки пластилина</li>
                <li>Трафареты</li>
                <li>Ваши золотые ручки и зоркий взгляд )</li>
            </ul>
        </div>

        <VideoView video-id="2056f867647a4a0b982c575814ec015a"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Домашнее задание:</b></p>
            <p>Изготовить из пластилина заготовки под пластик: тело, ноги, голову. Если вы делаете куклу из запекаемого пластика, можно использовать фольгу вместо пластилина.</p>

            <h2>3-ий шаг. Подготовка к обтяжке пластиком</h2>
            <p class='nomargin'>На этом занятии нам понадобятся:</p>
            <ul>
                <li>Трафареты</li>
                <li>Пищевая пленка</li>
                <li>Макетный нож</li>
                <li>Пластилиновые заготовки</li>
            </ul>
        </div>

        <VideoView video-id="2c57c63d992c4845afcb6d51cdabda4c"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Домашнее задание:</b></p>
            <ol>
                <li>Обтянуть тело, ноги, голову пищевой пленкой</li>
                <li>Расширить трафареты.</li>
            </ol>

            <h2>4-ый шаг. Обтяжка пластиком</h2>
             <p>Наконец, мы подошли к работе с пластиком! Хочу сказать, что очень важно научиться чувствовать материал, с которым вы работаете. Изучите его, поймите пользу и риски каждого его состояния. Сырой, мягкий, очень мягкий, почти жидкий, полусухой, полностью сухой. Поверьте, каждое состояние можно использовать с большой пользой для своей работы!</p>
        </div>

        <VideoView video-id="12a245072a1b47caadf19d5043809c6a"/>

        <div class="MBtextcont">
            <p><b>!!!</b> Очень важно уже завтра, примерно через 10-12 часов пока пластик будет в полусыром состоянии, вскрыть формы и достать пластилиновые заготовки. Т.е. верхний слой уже будет сухим и надежно держать форму, а внутренний еще влажный, поэтому его проще будет разрезать по бокам. С полностью сухими заготовками придется очень долго мучиться разрезать их сложно, особенно тело </p>

            <p class='nomargin'>На этом занятии нам понадобятся:</p>
            <ol>
                <li>Трафареты</li>
                <li>Пластик La Doll</li>
                <li>Нож или стек лопаткой</li>
                <li>Пластилиновые заготовки</li>
                <li>Коктейльные трубочки диамтером 4-5мм</li>
                <li>Пищевая пленка</li>
                <li>Вода</li>
            </ol>

            <p class='nomargin'><b>Домашнее задание:</b></p>
            <p>Обтянуть тело, ноги, руки, голову пластиком близко по трафаретам.</p>

            <h2>5-ый шаг. Достаем пластилин, соединяем</h2>
            <p>Как я уже говорила ранее, детали можно вскрывать уже на следующие сутки, но все зависит от влажности и температуры помещения. Иногда в дождливый сезон заготовки сохли пару суток и приходилось их осторожно вскрывать и досушивать в духовке, а иногда было так жарко и сухо, что уже через 12 часов они были готовы к вскрытию.</p>
            <p class='nomargin'><b>Как определить готовы ли детали?</b></p>
            <p>Верхний слой должен казаться совершенно сухим и твердым. Если на него нажать он может слегка промяться или даже не поддаться вашим воздействиям, а вот нож плавно и легко разрежет такой пластик. После снятия с пластилина они отлично держат форму.</p>
            <p class='nomargin'>На этом занятии нам понадобятся:</p>
            <ol>
                <li>Трафареты</li>
                <li>Пластик La Doll</li>
                <li>Нож или стек лопаткой</li>
                <li>Обтянутые пластиком заготовки</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Карандаш</li>
                <li>Макетный нож</li>
                <li>Вода</li>
            </ol>
        </div>

        <VideoView video-id="c4837e62bdf24348b5a968b10194b58a"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Домашнее задание:</b></p>
            <p>Достать пластилиновые основы из пластика и соединить детали обратно.</p>
            
            <h2>6-ой шаг. Делаем основу кисти</h2>
            <p>Пока наши основные детали сохнут, мы воспользуемся этим временем и подготовим заготовки для кисти! Достаточно кропотливый и медитативный процесс… Тут главное не торопиться и не забывать, что это только заготовки. Всю детализацию мы будем делать чуть позже.</p>
            <p>Главное поймите, что кисть — это не сложно, всего можно добиться, прилагая достаточно усилий и любви. Если вы освоите кисть, ваши куклы сразу поднимутся в качестве и станут значительно выразительней. Помните, первое на что смотрит искушённый зритель — это лицо и руки куклы! Так давайте не будем его разочаровывать.</p>
            <p class='nomargin'>На этом занятии нам понадобятся:</p>
            <ol>
                <li>Чертеж</li>
                <li>Пластик La Doll</li>
                <li>Проволока или скрепки канцелярские 5 см нерифлёные</li>
                <li>Ватный диск</li>
                <li>Клей Момент ПВА супер столяр (в красной или желтой бутылке)</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Карандаш, линейка</li>
                <li>Круглогубцы, плоскогубцы</li>
                <li>Вода</li>
                <li>Стек для мелкой лепки</li>
            </ol>
        </div>

        <VideoView video-id="f5ea67fbe64d4d49b3e15567e5a91536"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Домашнее задание:</b></p>
            <p>Подготовить основу для пальцев, обтянуть пластиком и собрать в кисть.</p>

            <h2>7-ой шаг. Делаем основу стопы</h2>
            <p class='nomargin'>На этом занятии нам понадобятся:</p>
            <ol>
                <li>Чертеж</li>
                <li>Пластик La Doll</li>
                <li>Проволока или скрепки канцелярские 5 см нерифлёные</li>
                <li>Ватный диск</li>
                <li>Клей Момент ПВА супер столяр (в красной или желтой бутылке)</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Карандаш, линейка</li>
                <li>Круглогубцы, плоскогубцы</li>
                <li>Вода</li>
                <li>Стек для мелкой лепки</li>
            </ol>
        </div>

        <VideoView video-id="9603994d04374dccb17f5f5a56e1b7de"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Домашнее задание:</b></p>
            <p>Подготовить основу для стопы и пальцев, обтянуть пластиком и собрать.</p>

            <h2>8-ой шаг. Анатомия руки</h2>
            <p>Наконец, мы подошли непосредственно к анатомии!</p>
            <p>Сегодня будем разбираться с рукой. Рука имеет свою форму и свои особенности строения, это не трубочка с ладошкой ) Руки тоже могут и должны быть выразительными. К чему я вас и призываю!</p>
            <p>Как подогнать руку по трафарету, как шкурить и исправлять ошибки, все это уже ждет вас! Смотрите, изучайте, применяйте!</p>
            <p class='nomargin'>На этом занятии нам понадобятся:</p>
            <ol>
                <li>Пластик La Doll</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Карандаш</li>
                <li>Трафарет руки</li>
                <li>Напильник, шкурка разной зернистости</li>
                <li>Макетный нож</li>
                <li>Следок</li>
                <li>Вода</li>
            </ol>            
        </div>

        <VideoView video-id="86c153a925d34d398d3b17b105fb1ad7"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Домашнее задание:</b></p>
            <p>Подогнать форму рук по трафарету и отшкурить.</p>

            <h2>9-ый шаг. Анатомия кисти</h2>
            <p>Ну как, вы еще в состоянии воспринимать информацию? А продолжать лепить? Вас ждет детальная проработка кисти!</p>
            <p>Я сама не ожидала, что такая маленькая часть тела таит в себе столько информации… Урок получился очень объемным и подробным. Даже не знаю хорошо это или плохо. Решать вам )</p>
            <p>И еще такой момент. Про ноготки. Я их очень редко леплю, в основном я их расписываю, то есть просто рисую. Это мой подход, поэтому в видео вы не найдёте эту информацию. Но я думаю это не будет для вас проблемой ) Вы же такие замечательные, трудолюбивые и талантливые!</p>
            <p class='nomargin'>На этом занятии нам понадобятся:</p>
            <ol>
                <li>Пластик La Doll</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Стеки для мелкой проработки</li>
                <li>Карандаш</li>
                <li>Чертеж кисти</li>
                <li>Надфиля, шкурка разной зернистости</li>
                <li>Макетный нож</li>
                <li>Следок</li>
                <li>Вода</li>
            </ol>   
        </div>

        <VideoView video-id="28b05171f74541cbb17525d4cd7130fd"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Домашнее задание:</b></p>
            <p>Проработать кисть и отшкурить. Придать ей окончательный вид.</p>

            <h2>10-ый шаг. Анатомия ноги</h2>
            <p class='nomargin'>На этом занятии нам понадобятся:</p>
            <ol>
                <li>Пластик La Doll</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Стеки</li>
                <li>Карандаш</li>
                <li>Чертеж</li>
                <li>Напильник, шкурка разной зернистости</li>
                <li>Макетный нож</li>
                <li>Следок</li>
                <li>Вода</li>
            </ol>   
        </div>

        <VideoView video-id="2e4242516a784b66a35281ad131ade85"/>


        <div class="MBtextcont">
            <p class='nomargin'><b>Домашнее задание:</b></p>
            <p>Проработать ногу и отшкурить. Придать ей окончательны вид.</p>

            <h2>11-ый шаг. Анатомия стоп</h2>
            <p>Как много в этой маленькой части тела странного и непривычного… На первый взгляд!</p>
            <p>Но это нас не остановит. Вас ждет очень много мелкой и медитативной работы. Учитесь чувствовать формы и объемы, совершенствуйте свои навыки в работе с пластиком и с мелкими деталями. Главное не торопитесь, если вам что-то не нравится дайте себе и работе немного отдохнуть, а детали высохнуть.</p>
            <p>Может позже, смотря на дело рук своих, вы поймете, что все не так плохо, как казалось, и достаточно внести пару изменений, чтобы нащупать нужный изгиб…</p>
            <p>Мы с вами так много проделали, что вы уже должны обладать определенными навыками в работе с пластиком. Доверяйте своему внутреннему процессу и чаще смотрите на оригинал! Я в вас верю, у нас все получится ))</p>
            <p class='nomargin'>На этом занятии нам понадобятся:</p>
            <ol>
                <li>Пластик La Doll</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Стеки</li>
                <li>Карандаш</li>
                <li>Чертеж</li>
                <li>Надфиля, шкурка разной зернистости</li>
                <li>Макетный нож</li>
                <li>Следок</li>
                <li>Вода</li>
            </ol>   
        </div>

        <VideoView video-id="f137d248f5aa4957876733e15c44454c"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Домашнее задание:</b></p>
            <p>Проработать кисть и отшкурить. Придать ей окончательный вид.</p>

            <h2>12-ый шаг. Анатомия тела. Перед</h2>
            <p>Лепить "её" или нет, вот в чем вопрос... Надеюсь вы догадались, о чем пойдет речь... Нет, это не грудь ) Смотрите урок!</p>
            <p class='nomargin'>На этом занятии нам понадобятся:</p>
            <ol>
                <li>Пластик La Doll</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Стеки</li>
                <li>Карандаш</li>
                <li>Чертеж</li>
                <li>Надфиля, шкурка разной зернистости</li>
                <li>Макетный нож</li>
                <li>Следок</li>
                <li>Вода</li>
            </ol>   
        </div>

        <VideoView video-id="fbe8ba8035cd49c49c0fb19634840dee"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Домашнее задание:</b></p>
            <p>Проработать переднюю часть тела.</p>

            <h2>13-ый шаг. Анатомия тела. Спина</h2>
            <p>Попка бабочкой! Вот с таким чудесным настроением мы проведем сегодня наше занятие! Оно, на удивление, вышло совсем коротким. Всё как вы любите, мало слов и много "тела"!</p>
            <p class='nomargin'>На этом занятии нам понадобятся:</p>
            <ol>
                <li>Пластик La Doll</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Стеки</li>
                <li>Карандаш</li>
                <li>Чертеж</li>
                <li>Надфиля, шкурка разной зернистости</li>
                <li>Макетный нож</li>
                <li>Следок</li>
                <li>Вода</li>
            </ol>   
        </div>

        <VideoView video-id="def65ae4d9d7417da4897f4ae2a2f98f"/>
        

        <div class="MBtextcont">
            <p class='nomargin'><b>Домашнее задание:</b></p>
            <p>Проработать заднюю часть тела.</p>

            <h2>14-ый шаг. Анатомия тела. Дорабатываем детали</h2>
            <p>Итак, последнее 14-ое занятие. Разбор полетов и маленькие хитрости которые помогут сделать тело куклы более выразительным!</p>
            <p class='nomargin'>На этом занятии нам понадобятся:</p>
            <ol>
                <li>Пластик La Doll</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Стеки</li>
                <li>Карандаш</li>
                <li>Чертеж</li>
                <li>Надфиля, шкурка разной зернистости</li>
                <li>Макетный нож</li>
                <li>Следок</li>
                <li>Вода</li>
            </ol>   
        </div>

        <VideoView video-id="6d8d6071611340058ee84ea9bababbc6"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Домашнее задание:</b></p>
            <p>Доработать тело.</p>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../Widgets/VideoView";
    import KCourse from "../Widgets/KCourse";
    export default {
        components: {
            KCourse,
            VideoView
        }
    }
</script>

<style>
    .MBcont {
        max-width: 1100px;
        margin: 0 auto;
        border-top: 1px solid #cbcbcb;
        padding: 30px;
        min-height: 300px;
        position: relative;
    }

    .MBcont h1 {
        font-size: 35pt;
        line-height: 45pt;
        text-align: left;
        margin: 0 0 20px 0;
        font-weight: normal;
    }
    .MBcont h2 {
        font-size: 24pt;
    }
    .MBcont .subtitle {
        position: relative;
        top: -25px;
        font-size: 10pt;
    }

    .MBtextcont {
        max-width: 550px;
        margin: 0 auto 15pt auto;
        font-size: 12pt;
        line-height: 22pt;
    }

    .MBtextcont h1 {
        margin-top: 20pt;
    }

    .MBtextcont h2 {
        font: 600 16pt/20pt "Montserrat", arial, sans-serif;
        text-align: left;
        margin: 40pt 0 15pt 0;
    }

    .MBtextcont ul {
        list-style: disc;
    }

    .MBtextcont ul,
    .MBtextcont ol {
        margin: 0;
        padding-left: 40pt;
    }

    .MBtextcont ul li,
    .MBtextcont ol li {
        margin-bottom: 0pt;
    }

    .MBtextcont p {
        margin-bottom: 15pt;
        margin-top: 0;
    }

    .MBtextcont p.nomargin {
        margin: 0pt;
    }

    .MBback {
        position: absolute;
        top: 0;
        left: 0;
    }

    /* .MBtextcont .a-download {
            padding: 5px 5px 5px 25px;
            background: url('/img/membersh/MB-icons.svg') no-repeat;
            background-size: 20px;
            background-position: 2px -75px;
        } */

    .MBtextcont h3 {
        font: 600 12pt/20pt "Montserrat", arial, sans-serif;
        text-align: left;
        margin: 0;
    }

    .MBtextcont a::after {
        content: "";
        border-top: 1px solid #b23642;
        position: absolute;
        width: 100%;
        left: 0;
        right: 100%;
        bottom: -3px;
        transition: width 0.4s ease-out;
    }

    .MBtextcont a:hover::after {
        width: 0;
    }
</style>